import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AlignType } from "rc-table/lib/interface";
import {
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography,
  Input as AntInput,
  DatePicker
} from "antd";
import { Form, Input } from "formik-antd";
import * as Yup from "yup";

import CompanyService from "../../utils/CompanyService";
import FinanceService from "../../utils/FinanceService";
import VoucherService from "../../utils/VoucherService";
import FileService from "../../utils/FileService";
import { ICompany, ICompanyDepartment, IInterpreterWithUser, ILanguage, ISpending } from "../../utils/interfaces";
import { stripeCountries } from "../../utils/stripeCountries";
import { imageUrl } from "../../helpers/urlHelpers";
import { transformPrice, truncate } from "../../helpers/transform";
import UseVoucher from "../../components/VoucherModals/UseVoucher";

import "./index.scss";
import MarkSpending from "../../components/VideosessionModals/MarkSpending";
import InviteEmployee from "../../components/InviteEmployeeModal";
import VideosessionDetails from "../../components/VideosessionModals/VideosessionDetails";
import { ErrorMessage, Formik } from "formik";
import InterpreterService from "../../utils/InterpreterService";
import MinimalPriceModal from "../../components/MinimalPriceModal";
import DiscountSpending from "../../components/VideosessionModals/DiscountSpending";
import CustomFeedbackModal from "../../components/CustomFeedbackModal";
import AdminService from "../../utils/AdminService";
import { useNavigate } from "react-router-dom";
import DepartmentModal from "../../components/DepartmentModal";
import VideosessionService from "../../utils/VideosessionService";
import { downloadSessionsExcel } from "../../helpers/exportSessionsCSV";
import LanguagesService from "../../utils/LanguagesService";

const { Option } = Select;

enum ModalTypes {
  INVITE = "INVITE",
  SPENDING = "SPENDING",
  MONTH_SPENDINGS = "MONTH_SPENDINGS",
  VOUCHER = "VOUCHER",
  HIDEN = "HIDEN",
  DETAILS = "DETAILS",
  MINIMAL_PRICE = "MINIMAL_PRICE",
  DISCOUNT = "DISCOUNT",
  FEEDBACK = "FEEDBACK",
  DEPARTMENTS = "DEPARTMENTS",
  REMOVE = "REMOVE",
  BLOCK_INTERPRETER = "BLOCK_INTERPRETER",
}

enum InvoiceStatus {
  NOT_AVAILABLE = "NOT_AVAILABLE",
  AVAILABLE = "AVAILABLE",
  GENERATED = "GENERATED",
  UNKNOWN = "UNKNOWN",
}

const columns = [
  {
    title: "Employee",
    dataIndex: "name",
    align: "center" as AlignType,
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    align: "center" as AlignType,
    key: "email",
  },
  {
    title: "Role",
    dataIndex: "role",
    align: "center" as AlignType,
    key: "role",
  },
  {
    title: "Cost Center",
    dataIndex: "costCenter",
    align: "center" as AlignType,
    key: "costCenter",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center" as AlignType,
    key: "status",
  },
  {
    title: "Department",
    dataIndex: "department",
    align: "center" as AlignType,
    key: "department",
  },
  {
    title: "Language",
    dataIndex: "language",
    align: "center" as AlignType,
    key: "language",
  },
  {
    title: "Actions",
    dataIndex: "id",
    align: "center" as AlignType,
    key: "id",
    render: (id: number, data: IEmployeeItem) => {
      switch (data.status) {
        case "invited":
          return (
            <Button
              onClick={() => data.createNewLink(id, data.email)}
              type="primary"
            >
              Create New Link
            </Button>
          );
        default:
          return "-";
      }
    },
  },
];

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(8, "Minimum 8 symbols!")
    .max(20, "Maximum 20 symbols!")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9]).+$/,
      "Should have at least one number and letter"
    )
    .required("Password is required"),
});

const { Title, Text } = Typography;

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getRoleTitle = (role: string) => {
  if (role === "companyAdmin") return "Admin";
  if (role === "company") return "Super Admin";
  return "Employee";
};

type EmployeeStatus = "invited" | "active" | "disabled";
interface IEmployeeItem {
  costCenter: string;
  email: string;
  role: string;
  id: number;
  name: string;
  language?: string;
  status: EmployeeStatus;
  createNewLink: (id: number, email: string) => void;
}

const Company: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [company, setCompany] = useState<ICompany>();
  const [employees, setEmployees] = useState<IEmployeeItem[]>([]);
  const [pastInterpreters, setPastInterpreters] = useState<IInterpreterWithUser[]>([]);
  const [selectedInterpreter, setSelectedInterpreter] = useState<number>();
  const [spendings, setSpendings] = useState<ISpending[]>([]);
  const [totalSpendingStats, setTotalSpendingStats] = useState({
    spending: 0,
    spendingPaid: 0,
    amountDiscounted: 0,
    spentMinutes: 0,
    outstandingBalance: 0,
  });
  const [spendingsMonth, setSpendingsMonth] = useState("");
  const [invoice, setInvoice] = useState<{
    name: string;
    status: InvoiceStatus;
  }>({
    status: InvoiceStatus.UNKNOWN,
    name: "",
  });
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [departmentLoading, setDepartmentLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [costCenterLoad, setCostCenterLoad] = useState(false);
  const [modal, setModal] = useState<{
    type: ModalTypes;
    code: string;
    email: string;
    id: number;
  }>({
    type: ModalTypes.HIDEN,
    code: "",
    email: "",
    id: 0,
  });
  const [superLoginStep, setSuperLoginStep] = useState(0);
  const [interpreterJWT, setInterpreterJWT] = useState("");
  const [code, setCode] = useState("");
  const [selectedSpending, setSelectedSpending] = useState<ISpending>();
  const [selectedDepartment, setSelectedDepartment] = useState<ICompanyDepartment>();
  const [costCenters, setCostCenters] = useState<string[]>([]);
  const [logsRange, setLogsRange] = useState<any>(null);
  const [loadingLogsBtn, setLoadingLogsBtn] = useState(false);
  const [spokenLanguages, setSpokenLanguages] = useState<ILanguage[]>([]);
  const [signLanguages, setSignLanguages] = useState<ILanguage[]>([]);

  useEffect(() => {
    if (!id) return;

    const init = async () => {
      const currentDate = new Date();
      const month = currentDate.getUTCMonth() + 1;
      const year = currentDate.getUTCFullYear();
      const date = `${month}-${year}`;
      setSpendingsMonth(date);

      const fetchedCompany = await CompanyService.getCompany(id);
      setCompany(fetchedCompany);
      const fetchedInterpreters = await CompanyService.getPastInterpreters(id);
      setPastInterpreters(fetchedInterpreters);

      const spoken = await LanguagesService.getSpokenLanguages();
      const sign = await LanguagesService.getSignLanguages();
      setSpokenLanguages(spoken);
      setSignLanguages(sign);
    };

    init();
  }, [id]);

  const calculateTotalSpendings = (spendings: ISpending[]) => {
    const calculatedTotalSpendings = spendings.reduce(
      (stats, videosession) => {
        stats.spending += videosession.spending;
        stats.spendingPaid += videosession.spendingPaid;
        stats.amountDiscounted += videosession.amountDiscounted;
        stats.spentMinutes +=
          videosession.videosessionSnapshot?.spentMinutes || 0;

        return stats;
      },
      {
        spending: 0,
        spendingPaid: 0,
        amountDiscounted: 0,
        spentMinutes: 0,
        outstandingBalance: 0,
      }
    );

    calculatedTotalSpendings.outstandingBalance =
      calculatedTotalSpendings.spending - calculatedTotalSpendings.spendingPaid;

    setTotalSpendingStats(calculatedTotalSpendings);
  };

  useEffect(() => {
    const getCompanySpendings = async () => {
      if (!id || !spendingsMonth) return;
      const fetchedSpendings = await FinanceService.getCompanySpendings(
        id,
        spendingsMonth
      );

      calculateTotalSpendings(fetchedSpendings);
      setSpendings(fetchedSpendings);
      const availableCostCenters = fetchedSpendings
        .filter((s) => s.costCenter?.length > 0)
        .map((s) => s.costCenter);
      setCostCenters([...new Set(availableCostCenters)]);

      try {
        const fetchedInvoice = await FinanceService.isInvoiceExist(
          id,
          spendingsMonth
        );
        if (fetchedInvoice.invoice === null) {
          setInvoice({
            status: InvoiceStatus.AVAILABLE,
            name: "",
          });
        } else {
          setInvoice({
            status: InvoiceStatus.GENERATED,
            name: fetchedInvoice.invoice,
          });
        }
      } catch (err: any) {
        if (err.response.status === 404) {
          setInvoice({
            status: InvoiceStatus.NOT_AVAILABLE,
            name: "",
          });
        }
      }
    };

    getCompanySpendings();
  }, [id, spendingsMonth]);

  useEffect(() => {
    if (!company) return;
    const employeesToSet = company?.employees.map((employee) => {
      let status = "invited" as EmployeeStatus;
      if (employee.user?.isBlocked) {
        status = "disabled";
      } else if (employee.user) {
        status = "active";
      }
      return {
        costCenter: employee.costCenter || "-",
        email: employee.email,
        role: getRoleTitle(employee.role),
        id: employee.id,
        name: employee.user
          ? `${employee.user.firstName} ${employee.user.lastName}`
          : "-",
        language: employee.user?.language,
        department: employee.departments?.length ? `${employee.departments[0].title_en}${employee.departments[0].parentDepartment ? `(${employee.departments[0].parentDepartment.title_en})` : ''}` : "-",
        status,
        createNewLink: createNewLink,
      };
    });

    setEmployees(employeesToSet);
  }, [company]);

  const createNewLink = async (id: number, email: string) => {
    const { code } = await CompanyService.getEmployeeRegisterCode(id);

    setModal({ type: ModalTypes.INVITE, id, code, email });
  };

  const handleClose = () => {
    setModal({
      type: ModalTypes.HIDEN,
      code: "",
      email: "",
      id: 0,
    });
  };

  const sendEmail = async () => {
    if (modal.type !== ModalTypes.INVITE || !modal.id) return;

    // unkoment when sending of emails will work
    // await CompanyService.sendInviteEmpoyeeEmail(employeeData.id);
    handleClose();
  };

  const handleInvoicePaymentUpdate = async () => {
    if (!id || !company) return;

    setPaymentLoading(true);
    const invoicePayment = await CompanyService.updateInvoicePayment(id);

    setCompany({
      ...company,
      invoicePayment,
    });
    setPaymentLoading(false);
  };

  const handleRequireDepartmentUpdate = async () => {
    if (!id || !company) return;

    try {
      setDepartmentLoading(true);
      const requireDepartment = await CompanyService.updateRequireDepartment(id);

      setCompany({
        ...company,
        requireDepartment,
      });
      setDepartmentLoading(false);
    } catch (err: any) {
      message.error(err?.response.data.message);
      setDepartmentLoading(false);
    }
  };

  const generateSelectOptions = () => {
    let currentDate = new Date();
    currentDate.setUTCDate(1);
    const options = [];

    do {
      const month = currentDate.getUTCMonth();
      const year = currentDate.getUTCFullYear();

      options.push(
        <Option
          value={`${month + 1}-${year}`}
        >{`${monthNames[month]} ${year}`}</Option>
      );

      currentDate.setUTCMonth(month - 1);
    } while (currentDate >= new Date(2021, 6));

    return options;
  };

  const activateVoucher = async (code: string) => {
    if (!id || !company) return;

    try {
      const response = await VoucherService.activateVoucher(code, id);

      setCompany({
        ...company,
        bonusBalance: response.bonusBalance,
      });

      message.success("Voucher activated!");

      handleClose();
    } catch (err: any) {
      message.error(err?.response.data.message);
    }
  };

  const updateMinimalPrice = async (minimalPrice: number) => {
    if (!id || !company) return;

    try {
      const response = await CompanyService.updateAdditionalPrice(
        +id,
        minimalPrice - 35
      );

      setCompany({
        ...company,
        additionalPrice: response,
      });

      message.success("Minimal price updated!");

      handleClose();
    } catch (err: any) {
      message.error(err?.response.data.message);
    }
  };

  const handleSpendingUpdate = (updatedSpending: ISpending) => {
    const spendingsToSet = spendings;
    for (const spending of spendingsToSet) {
      if (spending.id === updatedSpending.id) {
        spending.spendingPaid = updatedSpending.spendingPaid;
        spending.amountDiscounted = updatedSpending.amountDiscounted;
      }
    }

    setSpendings(spendingsToSet);
  };

  const handleError = (err: any) => {
    if (err.response?.data?.message) {
      message.error(err.response?.data?.message);
    } else {
      message.error("Something went wrong");
    }
  };

  const markSpendingAsPaid = async (
    date: string,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => {
    setSubmitting(true);
    if (!modal.id) return;

    try {
      if (modal.type === ModalTypes.SPENDING) {
        const updatedSpending = await FinanceService.markSpendingAsPaid(
          modal.id,
          date
        );

        handleSpendingUpdate(updatedSpending);
      } else if (modal.type === ModalTypes.MONTH_SPENDINGS) {
        const updatedSpendings = await FinanceService.markSpendingsAsPaid(
          modal.id,
          spendingsMonth,
          date
        );

        calculateTotalSpendings(updatedSpendings);
        setSpendings(updatedSpendings);
      }
      clearState();
      handleClose();
      message.success("Spending updated!");
    } catch (err: any) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const discountSpending = async (
    amount: number,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => {
    setSubmitting(true);
    if (!selectedSpending) return;

    try {
      if (modal.type === ModalTypes.DISCOUNT) {
        const updatedSpending = await FinanceService.discountSpending(
          selectedSpending.id,
          amount
        );

        handleSpendingUpdate(updatedSpending);
        clearState();
        handleClose();
        message.success("Spending updated!");
      }
    } catch (err: any) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const removeSpendingDiscount = async (spendingId: number) => {
    try {
      const updatedSpending = await FinanceService.removeDiscount(spendingId);
      handleSpendingUpdate(updatedSpending);
    } catch (err: any) {
      message.error(err.response.data.message);
    }
  };

  const generateMontlyInvoice = async () => {
    if (!id || !spendingsMonth) return;

    setGenerating(true);
    try {
      const { name } = await FinanceService.generateMonthlyInvoice(
        id,
        spendingsMonth
      );

      let timeout: NodeJS.Timeout;
      const checkInvoice = () => {
        const func = async () => {
          const { status } = await FinanceService.checkInvoiceStatus(name);
          if (status === "done") {
            setInvoice({
              status: InvoiceStatus.GENERATED,
              name,
            });
            setGenerating(false);
            if (timeout) clearTimeout(timeout);
          } else {
            checkInvoice();
          }
        };
        timeout = setTimeout(func, 1000);
      };
      checkInvoice();
    } catch (err: any) {
      message.error(err.response.data.message);
      setGenerating(false);
    }
  };

  const donwloadMonthlyInvoice = async () => {
    setDownloading(true);
    try {
      await FileService.downloadMonthlyInvoice(invoice.name);
    } catch (err) {
      message.error("Something went wrong!");
    }
    setDownloading(false);
  };

  const generateMontlyInvoiceByCostCenter = async (costCenter?: string) => {
    if (!costCenter || !id || !spendingsMonth) return;
    setCostCenterLoad(true);
    try {
      const { name } = await FinanceService.generateMonthlyInvoiceByCostCenter(
        id,
        spendingsMonth,
        costCenter
      );

      let timeout: NodeJS.Timeout;
      const checkInvoice = () => {
        const func = async () => {
          const { status } = await FinanceService.checkInvoiceStatus(name);
          if (status === "done") {
            await FileService.downloadMonthlyInvoice(name, costCenter);
            setCostCenterLoad(false);
            if (timeout) clearTimeout(timeout);
          } else {
            checkInvoice();
          }
        };
        timeout = setTimeout(func, 1000);
      };
      checkInvoice();
    } catch (err: any) {
      message.error(err.response.data.message);
      setCostCenterLoad(false);
    }
  };

  const removeFeedback = async () => {
    if (!company) return;
    const updatedCompany = await CompanyService.changeCustomFeedback(
      company.id,
      "",
      "",
      "",
      "",
      ""
    );

    setCompany({ ...company, ...updatedCompany });
    handleClose();
  };

  const addFeedback = async (
    customFeedbackUrl: string,
    titleEn: string,
    titleDe: string,
    titleFr: string,
    titleIt: string
  ) => {
    if (!company) return;
    const updatedCompany = await CompanyService.changeCustomFeedback(
      company.id,
      customFeedbackUrl,
      titleEn,
      titleDe,
      titleFr,
      titleIt
    );

    setCompany({ ...company, ...updatedCompany });
    handleClose();
  };

  const addDepartment = async (
    titleEn: string,
    titleDe: string,
    titleFr: string,
    titleIt: string,
    costCenter?: string,
    parentDepartmentId?: number,
    selectedDepartmentId?: number,
  ) => {
    if (!company) return;
    if (selectedDepartmentId)
      await CompanyService.updateDepartment(
        company.id,
        selectedDepartmentId,
        titleEn,
        titleDe,
        titleFr,
        titleIt,
        costCenter,
        parentDepartmentId
      );
    else
      await CompanyService.addDepartment(
        company.id,
        titleEn,
        titleDe,
        titleFr,
        titleIt,
        costCenter,
        parentDepartmentId
      );
    const fetchedCompany = await CompanyService.getCompany(`${company.id}`);
    setCompany(fetchedCompany);
    handleClose();
  };

  const removeDepartment = async (departementId: number) => {
    if (!company) return;
    await CompanyService.removeDepartment(
      company.id,
      departementId
    );

    const fetchedCompany = await CompanyService.getCompany(`${company.id}`);
    setCompany(fetchedCompany);
    handleClose();
  };

  const unblockInterpreter = async (interpreterId: number) => {
    if (!company) return;
    await CompanyService.unblockInterpreter(
      company.id,
      interpreterId
    );
    const fetchedCompany = await CompanyService.getCompany(`${company.id}`);
    setCompany(fetchedCompany);
    handleClose();
  };

  const blockInterpreter = async () => {
    if (!company || !selectedInterpreter) {
      message.error("Something went wrong");
      return;
    }
    try {
      await CompanyService.blockInterpreter(company.id, selectedInterpreter);
      setSelectedInterpreter(undefined)
      const fetchedCompany = await CompanyService.getCompany(`${company.id}`);
      setCompany(fetchedCompany);
      message.success(`Interpreter was blocked successfully`);
    } catch (err) {
      message.error("Something went wrong");
    }
    handleClose();
  };

  const handleCompanyDelete = async () => {
    const codeSecret = await AdminService.verifyMe();
    setModal({ ...modal, type: ModalTypes.REMOVE, code: codeSecret });
  };

  const confirmCompanyDelete = async () => {
    if (!company || !modal.code?.length || !code.length) {
      message.error("Something went wrong");
      return;
    }
    try {
      await CompanyService.deleteCompany(company.id, code, modal.code);
      message.success(`Company was removed successfully`);
      navigate("/admin/companies");
    } catch (err) {
      message.error("Something went wrong");
    }
  };


  const getLanguageTitle = (id: number, spoken: boolean) => {
    return (spoken ? spokenLanguages : signLanguages).find(
      (lang) => lang.id === id
    )?.title_de;
  };

  const handleDownloadLogs = async () => {
    const from = logsRange?.[0]?.startOf("day").toISOString();
    const to = logsRange?.[1]?.endOf("day").toISOString();
    setLoadingLogsBtn(true);
    try {
      const data = await VideosessionService.getVideosessions({
        companyName: company?.name,
        from,
        to,
        current: 1,
        pageSize: 100000,
      });
      await downloadSessionsExcel(data.videosessions, getLanguageTitle, `${from.split('T')[0]}_${to.split('T')[0]}`)
    } catch (err: any) {
      message.error(err?.response?.statusText || "Something went wrong!");
    }
    setLoadingLogsBtn(false);
  }

  const spendingColumns = [
    {
      title: "Title",
      dataIndex: ["videosessionSnapshot", "title"],
      align: "center" as AlignType,
      key: "title",
      render: (title: string, data: ISpending) => (
        <Button
          onClick={() =>
            setModal({ ...modal, id: data.id, type: ModalTypes.DETAILS })
          }
          type="link"
        >
          {title}
        </Button>
      ),
    },
    {
      title: "Total",
      dataIndex: "spending",
      align: "center" as AlignType,
      key: "spending",
      render: (spending: number) => transformPrice(spending),
    },
    {
      title: "Spending Paid",
      dataIndex: "spendingPaid",
      align: "center" as AlignType,
      key: "spendingPaid",
      render: (spendingPaid: number) => transformPrice(spendingPaid),
    },
    {
      title: "Discount",
      align: "center" as AlignType,
      key: "amountDiscounted",
      render: (data: ISpending) => {
        const { amountDiscounted, spending, spendingPaid, id } = data;
        return (
          <Space>
            {transformPrice(amountDiscounted)}
            {spending - spendingPaid > 0 && (
              <>
                <Button
                  onClick={() => {
                    setSelectedSpending(data);
                    setModal({
                      ...modal,
                      id: Number(id),
                      type: ModalTypes.DISCOUNT,
                    });
                  }}
                  type="default"
                  shape="circle"
                  icon={<PlusOutlined />}
                  size="small"
                  className=""
                ></Button>
              </>
            )}
            {amountDiscounted > 0 && (
              <Popconfirm
                title="Remove the discount"
                onConfirm={() => removeSpendingDiscount(Number(id))}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="default"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="small"
                  className=""
                ></Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
    {
      title: "Outstanding",
      align: "center" as AlignType,
      key: "amountDiscounted",
      render: (data: ISpending) =>
        transformPrice(data.spending - data.spendingPaid),
    },
    {
      title: "Date",
      dataIndex: "filterTime",
      align: "center" as AlignType,
      key: "filterTime",
      render: (filterTime: Date) =>
        new Date(filterTime).toLocaleString().slice(0, -3),
    },
    {
      title: "Mark as paid",
      align: "center" as AlignType,
      render: (data: any) => {
        const { spending, spendingPaid, id, invoicePayment } = data;
        const outstandingBalance = spending - spendingPaid > 0;

        return invoice.status !== InvoiceStatus.UNKNOWN &&
          invoice.status !== InvoiceStatus.NOT_AVAILABLE &&
          invoicePayment &&
          outstandingBalance ? (
          <Button
            onClick={() =>
              setModal({
                ...modal,
                id,
                type: ModalTypes.SPENDING,
              })
            }
            type="primary"
          >
            Mark
          </Button>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <div>
      {company && (
        <>
          <Space>
            <Title>{company.name} </Title>
            <Popconfirm
              title="Delete company?"
              onConfirm={handleCompanyDelete}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>

          <Row>
            <Col span={6}>
              <Avatar
                size={216}
                icon={<UserOutlined />}
                src={imageUrl(company.logo?.name)}
              />
              <Button
                type="primary"
                onClick={() => setSuperLoginStep(1)}
                style={{ marginTop: 15 }}
              >
                Login as: {`${company.user.firstName} ${company.user.lastName}`}
              </Button>
            </Col>
            <Col
              span={18}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Row>
                <Col span={12}>
                  <Space
                    direction="vertical"
                    style={{ alignItems: "start", minWidth: 215 }}
                  >
                    <Text strong>
                      Specialization: {company.specialization?.title_en}
                    </Text>
                    <Text strong>
                      Name:{" "}
                      {`${company.user.firstName} ${company.user.lastName}`}
                    </Text>
                    <Text strong>Email: {company.user.email}</Text>
                    <Text strong>
                      Outstanding Balance:{" "}
                      {transformPrice(company.outstandingBalance)} CHF
                    </Text>
                    <Text strong>
                      Invoice Payment:{" "}
                      <Switch
                        checked={company.invoicePayment}
                        loading={paymentLoading}
                        onChange={handleInvoicePaymentUpdate}
                      />
                    </Text>
                    <Text strong className="bonusBalance">
                      Bonus Balance:{" "}
                      {
                        <>
                          {transformPrice(company.bonusBalance)} CHF{" "}
                          <EditOutlined
                            style={{ fontSize: 20 }}
                            className="editBtn"
                            onClick={() =>
                              setModal({ ...modal, type: ModalTypes.VOUCHER })
                            }
                          />{" "}
                        </>
                      }
                    </Text>
                    <Text strong className="bonusBalance">
                      Additional Price:{" "}
                      {
                        <>
                          {transformPrice(company.additionalPrice + 3500)} CHF{" "}
                          <EditOutlined
                            style={{ fontSize: 20 }}
                            className="editBtn"
                            onClick={() =>
                              setModal({
                                ...modal,
                                type: ModalTypes.MINIMAL_PRICE,
                              })
                            }
                          />{" "}
                        </>
                      }
                    </Text>
                    <Text strong className="bonusBalance">
                      Custom feedback:{" "}
                      {!company.customFeedbackUrl?.length && (
                        <PlusOutlined
                          style={{ fontSize: 20 }}
                          className="editBtn"
                          onClick={() =>
                            setModal({
                              ...modal,
                              type: ModalTypes.FEEDBACK,
                            })
                          }
                        />
                      )}
                    </Text>
                    {company.customFeedbackUrl && (
                      <Text>
                        <a href="company.customFeedbackUrl" target="_blank">
                          {truncate(company.customFeedbackUrl, 30)}
                        </a>
                        <EditOutlined
                          style={{ fontSize: 20 }}
                          className="editBtn"
                          onClick={() =>
                            setModal({
                              ...modal,
                              type: ModalTypes.FEEDBACK,
                            })
                          }
                        />{" "}
                        <Popconfirm
                          title="Remove custom feedback?"
                          onConfirm={() => removeFeedback()}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined
                            style={{ fontSize: 20 }}
                            className="editBtn"
                          />
                        </Popconfirm>{" "}
                      </Text>
                    )}
                    <Text strong className="bonusBalance">
                      Blocked Interpreters:{" "}

                      <PlusOutlined
                        style={{ fontSize: 20 }}
                        className="editBtn"
                        onClick={() => {
                          setSelectedDepartment(undefined);
                          setModal({
                            ...modal,
                            type: ModalTypes.BLOCK_INTERPRETER,
                          })
                        }
                        }
                      />
                    </Text>
                    {company.blockedInterpreters.map(interpreter => (
                      <Text>
                        {interpreter.user.firstName} {interpreter.user.lastName}
                        <Popconfirm
                          title="Unblock interpreter?"
                          onConfirm={() => unblockInterpreter(interpreter.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined
                            style={{ fontSize: 20 }}
                            className="editBtn"
                          />
                        </Popconfirm>{" "}
                      </Text>))}
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical" style={{ alignItems: "start" }}>
                    <Text strong>Address</Text>
                    <Text>City: {company.address.city}</Text>
                    <Text>
                      Country:{" "}
                      {company.address.country &&
                        (stripeCountries as any)[
                        company.address.country.toUpperCase()
                        ]}
                    </Text>
                    <Text>Street: {company.address.street}</Text>
                    <Text>Postal Code: {company.address.postalCode}</Text>
                    {company.billingAddress && (
                      <>
                        <Text strong>Billing Address</Text>
                        <Text>City: {company.billingAddress.city}</Text>
                        <Text>
                          Country:{" "}
                          {company.billingAddress.country &&
                            (stripeCountries as any)[
                            company.billingAddress.country.toUpperCase()
                            ]}
                        </Text>
                        <Text>Street: {company.billingAddress.street}</Text>
                        <Text>
                          Postal Code: {company.billingAddress.postalCode}
                        </Text>
                      </>
                    )}
                    {company.billingName && (
                      <Text strong>Billing Name: {company.billingName}</Text>
                    )}
                    {company.billingName2 && (
                      <Text strong>Billing Name2: {company.billingName2}</Text>
                    )}
                    {company.billingName3 && (
                      <Text strong>Billing Name3: {company.billingName3}</Text>
                    )}
                    <Text strong>
                      Require department:{" "}
                      <Switch
                        checked={company.requireDepartment}
                        loading={departmentLoading}
                        onChange={handleRequireDepartmentUpdate}
                      />
                    </Text>
                    <Text strong className="bonusBalance">
                      Departments:{" "}

                      <PlusOutlined
                        style={{ fontSize: 20 }}
                        className="editBtn"
                        onClick={() => {
                          setSelectedDepartment(undefined);
                          setModal({
                            ...modal,
                            type: ModalTypes.DEPARTMENTS,
                          })
                        }
                        }
                      />
                    </Text>
                    {company.companyDepartments.map(department => (
                      <Text>
                        {truncate(department.title_en, 30)}{department.parentDepartment && `(${department.parentDepartment.title_en})`}
                        <EditOutlined
                          style={{ fontSize: 20 }}
                          className="editBtn"
                          onClick={() => {
                            setSelectedDepartment(department)
                            setModal({
                              ...modal,
                              type: ModalTypes.DEPARTMENTS,
                            })
                          }
                          }
                        />{" "}
                        <Popconfirm
                          title="Remove department?"
                          onConfirm={() => removeDepartment(department.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined
                            style={{ fontSize: 20 }}
                            className="editBtn"
                          />
                        </Popconfirm>{" "}
                      </Text>))}
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 30 }} span={20} offset={2}>
                  <Space direction="vertical" style={{ alignItems: "start" }}>
                    <Text strong>Description: {company.about ?? "-"}</Text>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col span={24}>
              <Title level={3} style={{ marginTop: "3rem" }}>
                Get All Session Logs:
              </Title>
            </Col>
            <Col span={24}>
              <Text strong>
                <DatePicker.RangePicker onChange={setLogsRange} />
              </Text>
              <Button
                loading={loadingLogsBtn}
                type="primary"
                onClick={handleDownloadLogs}
                style={{ marginLeft: 15 }}
                disabled={!logsRange}
              >
                Download
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            {!!company.employees.length && (
              <Col span={24}>
                <Space direction="vertical" style={{ alignItems: "start" }}>
                  <Title level={3}>Employees:</Title>
                </Space>
                <Table
                  dataSource={employees}
                  columns={columns}
                  pagination={false}
                />
              </Col>
            )}
          </Row>

          <Row style={{ marginTop: 50 }}>
            <Col span={24}>
              <Space direction="horizontal" style={{ alignItems: "start" }}>
                <Title level={3}>Videosessions:</Title>
                <Select
                  value={spendingsMonth}
                  onChange={setSpendingsMonth}
                  style={{ width: 240 }}
                >
                  {generateSelectOptions()}
                </Select>
                {invoice.status === "NOT_AVAILABLE" && (
                  <Button type="primary" disabled>
                    No monthly spending
                  </Button>
                )}
                {invoice.status === "AVAILABLE" && (
                  <Button
                    type="primary"
                    loading={generating}
                    disabled={generating}
                    onClick={generateMontlyInvoice}
                  >
                    Generate Invoice
                  </Button>
                )}
                {invoice.status === "GENERATED" && (
                  <>
                    <Button
                      type="primary"
                      onClick={donwloadMonthlyInvoice}
                      loading={downloading}
                      disabled={downloading}
                    >
                      Download invoice
                    </Button>
                    <Popconfirm
                      title="Remove existing and generate new invoice?"
                      onConfirm={generateMontlyInvoice}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="default"
                        loading={generating}
                        disabled={generating}
                      >
                        Regenerate
                      </Button>
                    </Popconfirm>
                  </>
                )}
                {!!costCenters.length && (
                  <Select
                    placeholder="Cost center invoice"
                    onSelect={(e: any) =>
                      generateMontlyInvoiceByCostCenter(e?.toString())
                    }
                    loading={costCenterLoad}
                    disabled={costCenterLoad}
                    options={costCenters.map((c) => ({ value: c, label: c }))}
                  >
                    Cost center invoice
                  </Select>
                )}
              </Space>
              <Table
                className="table-striped-columns"
                dataSource={spendings}
                columns={spendingColumns}
                pagination={{
                  pageSizeOptions: ["10", "25", "50"],
                  showSizeChanger: true,
                }}
                summary={() => (
                  <Table.Summary.Row className="totalStats">
                    <Table.Summary.Cell index={1}>
                      <Text strong>Total for month:</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <Text strong>
                        {transformPrice(totalSpendingStats.spending)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <Text strong>
                        {transformPrice(totalSpendingStats.spendingPaid)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <Text strong>
                        {transformPrice(totalSpendingStats.amountDiscounted)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      <Text strong>
                        {transformPrice(totalSpendingStats.outstandingBalance)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                      {invoice.status !== InvoiceStatus.UNKNOWN &&
                        invoice.status !== InvoiceStatus.NOT_AVAILABLE &&
                        totalSpendingStats.outstandingBalance > 0 ? (
                        <Button
                          onClick={() =>
                            setModal({
                              ...modal,
                              id: Number(id),
                              type: ModalTypes.MONTH_SPENDINGS,
                            })
                          }
                          type="primary"
                        >
                          Month paid
                        </Button>
                      ) : (
                        "-"
                      )}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              />
            </Col>
          </Row>
        </>
      )}

      <InviteEmployee
        show={modal.type === ModalTypes.INVITE}
        data={modal}
        handleClose={handleClose}
        confirm={sendEmail}
      />

      <UseVoucher
        show={modal.type === ModalTypes.VOUCHER}
        handleClose={handleClose}
        confirm={activateVoucher}
      />

      <MinimalPriceModal
        show={modal.type === ModalTypes.MINIMAL_PRICE}
        additionalPrice={(company?.additionalPrice ?? 0) / 100}
        handleClose={handleClose}
        confirm={updateMinimalPrice}
      />

      <MarkSpending
        show={
          modal.type === ModalTypes.SPENDING ||
          modal.type === ModalTypes.MONTH_SPENDINGS
        }
        handleClose={handleClose}
        confirm={markSpendingAsPaid}
      />

      <DiscountSpending
        show={modal.type === ModalTypes.DISCOUNT}
        handleClose={handleClose}
        confirm={discountSpending}
        currentDiscount={selectedSpending?.amountDiscounted ?? 0}
        maxDiscount={selectedSpending?.spending ?? 0}
      />

      <VideosessionDetails
        show={modal.type === ModalTypes.DETAILS}
        data={
          modal.type === ModalTypes.DETAILS
            ? spendings.find((spending) => spending.id === modal.id)
            : undefined
        }
        handleClose={handleClose}
      />
      <CustomFeedbackModal
        show={modal.type === ModalTypes.FEEDBACK}
        handleClose={handleClose}
        confirm={addFeedback}
        customFeedbackUrl={company?.customFeedbackUrl}
        titleEn={company?.customFeedbackTitle?.en}
        titleDe={company?.customFeedbackTitle?.de}
        titleFr={company?.customFeedbackTitle?.fr}
        titleIt={company?.customFeedbackTitle?.it}
      />
      {modal.type === ModalTypes.DEPARTMENTS &&
        <DepartmentModal
          show={modal.type === ModalTypes.DEPARTMENTS}
          handleClose={handleClose}
          confirm={addDepartment}
          selectedDepartmentId={selectedDepartment?.id}
          titleEn={selectedDepartment?.title_en}
          titleDe={selectedDepartment?.title_de}
          titleFr={selectedDepartment?.title_fr}
          titleIt={selectedDepartment?.title_it}
          costCenter={selectedDepartment?.costCenter}
          parentDepartmentId={selectedDepartment?.parentDepartment?.id}
          companyDepartments={company?.companyDepartments}
        />}

      <Modal
        title="Enter code"
        visible={modal.type === ModalTypes.REMOVE}
        onOk={confirmCompanyDelete}
        onCancel={handleClose}
      >
        <AntInput value={code} onChange={(v) => setCode(v.target.value)} />
      </Modal>

      <Modal
        title="Select interpreter to block"
        visible={modal.type === ModalTypes.BLOCK_INTERPRETER}
        onOk={blockInterpreter}
        onCancel={handleClose}
      >
        <Select
          value={selectedInterpreter}
          onChange={(v) => setSelectedInterpreter(v)}
          style={{ width: '100%' }}
          showSearch
          filterOption={(input, option) =>
            !!(option?.label as string)?.toLowerCase().includes(`${input}`.toLowerCase())
          }
        >
          {pastInterpreters.filter(interpreter =>
            !company?.blockedInterpreters.find(({ id }) => id === interpreter.id))
            .map(interpreter => (
              <Select.Option
                key={interpreter.id}
                value={interpreter.id}
                label={`${interpreter.user.firstName} ${interpreter.user.lastName} (${interpreter.id})`}
              >
                {interpreter.user.firstName} {interpreter.user.lastName} ({interpreter.id})
              </Select.Option>
            ))}
        </Select>
      </Modal>

      <Modal
        title={`Login as: ${company?.user.firstName} ${company?.user.lastName}`}
        visible={!!superLoginStep}
        onCancel={() => setSuperLoginStep(0)}
        footer={null}
      >
        {superLoginStep === 1 && (
          <Formik
            initialValues={{ password: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              setSubmitting(true);

              try {
                const code = await InterpreterService.loginAsInterpreter(
                  company?.user?.email as string,
                  values.password
                );
                setInterpreterJWT(code);
                setSuperLoginStep(2);
              } catch (err: any) {
                const { message } = err.response.data;
                setFieldError("password", message);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, submitForm }) => (
              <Form>
                <div className="loginGroup">
                  <div className="inputGroup">
                    <Text strong>Confirm password:</Text>
                    <Input
                      style={{ maxWidth: 250 }}
                      type="password"
                      name="password"
                    />
                    <ErrorMessage
                      name="password"
                      component="p"
                      className="error"
                    />
                  </div>
                  <Button
                    type="primary"
                    loading={isSubmitting}
                    onClick={() => submitForm()}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
        {superLoginStep === 2 && (
          <div>
            <p>
              At the dolmx.ch add to the localStorage{" "}
              <Text strong>@access_token</Text> key with this code as value:
            </p>
            <div className="linkGroup">
              <LinkOutlined />
              <p>{interpreterJWT}</p>
              <Tooltip title="Copied!" trigger="click">
                <Button
                  type="primary"
                  onClick={() => navigator.clipboard.writeText(interpreterJWT)}
                >
                  Copy
                </Button>
              </Tooltip>
              ,
            </div>

            <div className="footerBtnGroup">
              <Button
                onClick={() => {
                  setSuperLoginStep(0);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Company;
